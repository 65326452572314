
import {
  defineComponent, onBeforeMount, reactive,
} from 'vue';
import { useAppBase } from '@/core/composables/AppBase';
import { IPreco } from '@/models/Entidades/IPreco';
import ComunicacaoApi from '@/core/components/UI/ComunicacaoApi.vue';
import ServicoPreco from '@/servicos/ServicoPreco';
import { IResposta } from '@/core/models/IResposta';

export default defineComponent({
  name: 'PrecoCadastro',
  components: {
    ComunicacaoApi,
  },
  setup() {
    const {
      appBase, apresentarComunicacaoApi, apresentarRespostaRedirecionamento, obterCodigoRota,
    } = useAppBase();
    const servicoPreco = new ServicoPreco();

    const state = reactive({
      Preco: {} as IPreco,
    });

    async function obterPreco(codigo:number) {
      appBase.carregando = true;
      state.Preco = await servicoPreco.obter(codigo);
      appBase.carregando = false;
    }

    function limparDados() {
      state.Preco = {} as IPreco;
      state.Preco.codigo = 0;
      state.Preco.status = true;
      state.Preco.casasDecimais = 2;
    }

    onBeforeMount(async () => {
      limparDados();
      const codigoPreco = obterCodigoRota();
      if (codigoPreco > 0) {
        await obterPreco(codigoPreco);
      }
    });

    async function salvar() {
      appBase.resposta = {} as IResposta;
      apresentarComunicacaoApi('Aguarde por favor... Estamos salvando as informações do Preço.');
      if (state.Preco.codigo === 0) {
        appBase.resposta = await servicoPreco.incluir(state.Preco);
      } else {
        appBase.resposta = await servicoPreco.atualizar(state.Preco);
      }

      apresentarRespostaRedirecionamento('Precos');
    }
    return {
      appBase,
      state,
      salvar,
    };
  },
});
